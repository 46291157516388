<template>
  <div class="row mx-0 my-3 justify-content-center">
    <div class="col-12 text-center medium bigger_font"  >
      <span>
        Please login to complete donation
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Recurring LoginSection',
  data () {
    return {
      isLogin: false
    }
  }
}
</script>
<style scoped>
  .bigger_font {
    font-size: 20px;
  }
</style>
